import { MenuItemTypes } from 'src/lib/constants';
import { NAVIGATION_EVENTS } from 'src/lib/trackingEvents';

// US nav data
const enUS = {
  items: [
    {
      type: MenuItemTypes.link,
      title: 'Why Plaid',
      href: '/why-plaid/',
    },
    {
      type: MenuItemTypes.dropdown,
      title: 'Products',
      sx: { pb: { lg: '0' } },
      isWrapping: false,
      children: [
        {
          type: MenuItemTypes.dropdown,
          title: 'Payments',
          children: [
            {
              type: MenuItemTypes.link,
              title: 'Overview',
              description: 'Explore payments solution',
              href: '/solutions/payments/',
            },
            {
              type: MenuItemTypes.link,
              title: 'Auth',
              description: 'Verified account and routing numbers',
              href: '/products/auth/',
            },
            {
              type: MenuItemTypes.link,
              title: 'Identity',
              description: 'Bank account-holder information',
              href: '/products/identity/',
            },
            {
              type: MenuItemTypes.link,
              title: 'Balance',
              description: 'Real-time balance checks',
              href: '/products/balance/',
            },
            {
              type: MenuItemTypes.link,
              title: 'Signal',
              description: 'ACH risk assessment and scoring',
              href: '/products/signal/',
            },
            {
              type: MenuItemTypes.link,
              title: 'Transfer',
              description: 'ACH, RTP, and FedNow payment processing',
              href: '/products/transfer/',
            },
          ],
        },
        {
          type: MenuItemTypes.dropdown,
          title: 'Fraud and Compliance',
          children: [
            {
              type: MenuItemTypes.link,
              title: 'Overview',
              description: 'Explore fraud and compliance solution',
              href: '/solutions/fraud-compliance/',
            },
            {
              type: MenuItemTypes.link,
              title: 'Identity Verification',
              description: 'Global KYC and anti-fraud',
              href: '/products/identity-verification/',
            },
            {
              type: MenuItemTypes.link,
              title: 'Beacon',
              description: 'Anti-fraud network',
              href: '/products/beacon/',
            },
            {
              type: MenuItemTypes.link,
              title: 'Signal',
              description: 'ACH risk assessment and scoring',
              href: '/products/signal/',
            },
            {
              type: MenuItemTypes.link,
              title: 'Monitor',
              description: 'AML watchlist screening',
              href: '/products/monitor/',
            },
            {
              type: MenuItemTypes.link,
              title: 'Identity',
              description: 'Bank account-holder information',
              href: '/products/identity/',
            },
          ],
        },
        {
          type: MenuItemTypes.dropdown,
          title: 'Personal Finance Insights',
          children: [
            {
              type: MenuItemTypes.link,
              title: 'Overview',
              description: 'Explore the personal finance insights solution',
              href: '/solutions/personal-financial-management/',
            },
            {
              type: MenuItemTypes.link,
              title: 'Transactions',
              description: 'Up to 24 months of categorized data',
              href: '/products/transactions/',
            },
            {
              type: MenuItemTypes.link,
              title: 'Investments',
              description: 'Retirement, brokerage, and crypto data',
              href: '/products/investments/',
            },
            {
              type: MenuItemTypes.link,
              title: 'Liabilities',
              description: 'Credit card and mortgage data',
              href: '/products/liabilities/',
            },
            {
              type: MenuItemTypes.link,
              title: 'Enrich',
              description: 'Cleanse and categorize internal transactions',
              href: '/products/enrich/',
            },
          ],
        },
        {
          type: MenuItemTypes.dropdown,
          title: 'Credit Underwriting',
          children: [
            {
              type: MenuItemTypes.link,
              title: 'Overview',
              description: 'Explore the credit underwriting insights solution',
              href: '/solutions/credit/',
            },
            {
              type: MenuItemTypes.link,
              title: 'Consumer Report',
              description: 'Cash flow insights powered by Plaid Check',
              href: '/check/consumer-report/',
            },
            {
              type: MenuItemTypes.link,
              title: 'Assets',
              description: "Point-in-time snapshots of users' finances",
              href: '/products/assets/',
            },
            {
              type: MenuItemTypes.link,
              title: 'Income',
              description: 'Income and employment verification',
              href: '/products/income/',
            },
          ],
        },
        {
          type: MenuItemTypes.dropdown,
          title: 'Open Finance',
          children: [
            {
              type: MenuItemTypes.link,
              title: 'Overview',
              description: 'Explore open finance solution',
              href: '/solutions/open-finance/',
            },
            {
              type: MenuItemTypes.link,
              title: 'Core Exchange',
              description: 'Industry-aligned API connectivity',
              href: '/products/core-exchange/',
            },
            {
              type: MenuItemTypes.link,
              title: 'Permissions Manager',
              description: 'Embeddable consumer permissions management',
              href: '/products/permissions-manager/',
            },
            {
              type: MenuItemTypes.link,
              title: 'App Directory',
              description: 'App insights to help manage risk and compliance',
              href: '/products/app-directory/',
            },
          ],
        },
        {
          type: MenuItemTypes.link,
          title: 'Layer',
          href: '/layer/',
          description: 'Boost conversion with instant onboarding',
        },
        {
          type: MenuItemTypes.link,
          title: 'Link',
          href: '/plaid-link/',
          description: 'Instantly link users’ financial accounts',
          sx: {
            backgroundColor: { lg: 'white' },
          },
        },
      ],
    },
    {
      type: MenuItemTypes.dropdown,
      title: 'Use cases',
      sx: { pb: { lg: '20px' } },
      isWrapping: false,
      children: [
        {
          type: MenuItemTypes.link,
          title: 'Lending',
          href: '/use-cases/lending/',
        },
        {
          type: MenuItemTypes.link,
          title: 'Wealth',
          href: '/use-cases/wealth/',
        },
        {
          type: MenuItemTypes.link,
          title: 'Pay by bank',
          href: '/pay-by-bank/',
        },
        {
          type: MenuItemTypes.link,
          title: 'Digital banking',
          href: '/use-cases/digital-banking/',
        },
        {
          type: MenuItemTypes.link,
          title: 'Business finances',
          href: '/use-cases/business-finances/',
        },
        {
          type: MenuItemTypes.link,
          title: 'Crypto',
          href: '/use-cases/crypto/',
        },
        {
          type: MenuItemTypes.link,
          title: 'Property management',
          href: '/plaid-for-property-management/',
        },
      ],
    },
    {
      type: MenuItemTypes.link,
      title: 'Pricing',
      href: '/pricing/',
      trackingId: NAVIGATION_EVENTS.PRIMARY.USER_CLICKED_PRIMARY_NAV_PRICING,
    },
    {
      type: MenuItemTypes.dropdown,
      title: 'Developers',
      children: [
        {
          type: MenuItemTypes.link,
          title: 'Quickstart',
          href: '/docs/quickstart/',
        },
        {
          type: MenuItemTypes.link,
          title: 'API documentation',
          href: '/docs/',
        },
        {
          type: MenuItemTypes.link,
          title: 'Libraries',
          href: '/docs/api/libraries/',
        },
        {
          type: MenuItemTypes.link,
          title: 'Github',
          href: 'https://github.com/plaid/',
        },
        {
          type: MenuItemTypes.link,
          title: 'Link demo',
          href: '/demo/',
        },
      ],
    },
    {
      type: MenuItemTypes.dropdown,
      title: 'Resources',
      sx: { pb: { lg: '0' } },
      isWrapping: false,
      children: [
        {
          type: MenuItemTypes.link,
          title: 'What’s new',
          href: '/whats-new/fall-2024/',
        },
        { type: MenuItemTypes.link, title: 'Blog', href: '/blog/' },
        {
          type: MenuItemTypes.link,
          title: 'Library',
          href: '/resources/library/',
        },
        {
          type: MenuItemTypes.link,
          title: 'Industry resources',
          href: '/resources/',
        },
        {
          type: MenuItemTypes.link,
          title: 'Customer stories',
          href: '/customer-stories/',
        },
        {
          type: MenuItemTypes.link,
          title: 'Annual conference',
          href: '/events/effects/',
        },
        {
          type: MenuItemTypes.link,
          title: 'What is Plaid',
          href: '/what-is-plaid/',
        },
        {
          type: MenuItemTypes.heading,
          title: 'For consumers',
          sx: {
            backgroundColor: { lg: 'black200' },
          },
        },
        {
          type: MenuItemTypes.link,
          title: 'Control your data with Plaid Portal',
          href: '//my.plaid.com/',
          sx: {
            backgroundColor: { lg: 'black200' },
          },
        },
        {
          type: MenuItemTypes.link,
          title: 'How we handle data',
          href: '/how-we-handle-data/',
          sx: {
            backgroundColor: { lg: 'black200' },
          },
        },
        {
          type: MenuItemTypes.link,
          title: 'Discover Plaid-powered apps',
          href: '/discover-apps/',
          sx: {
            backgroundColor: { lg: 'black200' },
            paddingBottom: '16px', // Add paddingBottom property here
          },
        },
      ],
    },
  ],
  ctaTrackingIds: {
    getAPIkeys: NAVIGATION_EVENTS.PRIMARY.GET_API_KEYS_BUTTON,
  },
};

export const navData = {
  'en-us': enUS,
  // TODO: Add data for other locales here
};
